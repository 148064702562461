body {
  background-color: #ffffff;
  margin: 0;
}

.site-color {
  color: #64e986;
  /* color: red; */
}
/* Google Fonts */
.font-regular {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05em;
}
.font-medium {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.font-semibold {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.font-medium-italic {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-style: italic;
}
.font-semibold-italic {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 90px 0px 10px; /* Add padding
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
  height: 70px;
  /* border: 1px solid red; */
}

/* Logo styles */
.logo img {
  width: 200px; /* Adjust logo width */
  height: auto; /* Maintain aspect ratio */
  /* border: 1px solid red; */
}

/* Links styles */
.links a {
  /* border: 1px solid red; */
  color: inherit; /* Inherit text color */
  text-decoration: none; /* Remove default underline */
  margin-left: 70px; /* Add space between links */
  font-size: 18px;
}

/* Hover styles */
.links a:hover {
  color: #54c47a; /* Change color on hover */
}


/* Introduction text section styles */
.intro-section {
  display: flex; /* Use flexbox to arrange items */
  /* border: 1px solid red; */
  height: 530px;
}

/* Text section styles */
.text-section {
  padding: 0px 30px; /* Add padding to text section */
  /* border: 1px solid red; */
}

.introduction {
  padding-top: 100px;
}

/* Image section styles */
.image-section {
  background-size: contain; /* Scale background image to cover */
  background-repeat: no-repeat;
  background-position: center; /* Center background image */
  /* border: 1px solid red; */
}

/* Component.css */
.category-section {
  padding-top: 50px;
  text-align: center;
}

.categories-container {
  display: flex;
  flex-direction: column;
  height: 360px;
  /* border: 1px solid red; */
}

.row {
  display: flex;
  flex: 1;
}

.category {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #e0ffff; */
  background-color: #f5f5f7;
  border-radius: 15px;
  margin: 10px;
  padding: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.category-name {
  /* border: 1px solid #000; */
  margin-top: 10px; /* Adjust spacing between name and icon */
  height: 44px;
  display: flex;
  align-items: center;
}

.category-icon {
  margin-top: 20px;
}

.category-link {
  text-decoration: none;
  color: inherit;
}

.category:hover {
  color: #f5f5f7;
  background-color: #64e986;
}

.report-block {
  display: flex;
  /* border: 1px solid #000; */
  margin: 15px;
  border-radius: 15px;
  /* background-color: #f0ffff; */
  background-color: #f5f5f7;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.report-description {
  flex: 5;
  /* border: 1px solid #000; */
  padding: 15px;
}

.report-pricing {
  flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 0px 15px 15px 0px;
  /* box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.5); */
}

.report-tags {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* padding-top: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #55c57a; */
}

.button {
  /* margin-top: 10px; */
  padding: 10px 20px;
  font-size: 16px;
  /* width: 200px; */
  background-color: #64e986;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 .button:hover {
  background-color: #54c47a;
}

.request-link a {
  font-weight: 800;
  padding-right: 10px;
}
.request-link a:hover {
  color: #54c47a;
}

.footer-section {
  margin-top: 50px;
  background-color: #64e986;
  height: 930px;
  padding-top: 50px;
}

.footer-form {
  border: 1px solid #ccc;  
  border-radius: 30px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  background-color: white;
}

/* Form Styles */
.form-container {
  /* border-right: 1px solid #ccc; */
  padding: 20px;
  /* margin-right: 20px; */
  flex: 2;
}

/* $primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b; */
.form__group {
  position: relative;
  padding: 15px 15px 0 0;
  margin-top: 10px;
  width: 50%;
}
.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}
.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #11998e;
    font-weight:500;    
  }
  padding-bottom: 6px;  
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e ,#38ef7d);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

.form__select {
  font-family: inherit;
  width: 100%;
  border: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #9b9b9b;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__select:focus {
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e ,#38ef7d);
  border-image-slice: 1;
}
.form__select:valid {
  color: black;
}
.form__select option {
  color: black; /* Ensure all options are black */
}

.radio-group {
  display: flex;
  /* flex-direction: column; */
}

.radio-label {
  font-family: inherit;
  font-size: 1.3rem;
  color: #9b9b9b;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-label input {
  margin-right: 10px;
  width: 1rem; /* Adjust this size as needed */
  height: 1rem; /* Adjust this size as needed */
  appearance: none;
  border: 2px solid #9b9b9b;
  border-radius: 50%;
  outline: none;
  background-color: #fff;
  transition: background-color 0.2s, border-color 0.2s;
}

.radio-label input:checked {
  background-color: #64e986;
  border-color: #64e986;
}

.radio-label input:checked + span {
  color: black;
}

.radio-label span {
  font-size: 1.3rem;
}



/* Testimony Styles */
.testimony-container {
  flex: 1;
  background-color: #f5f5f7;
  margin: 20px;
  border-radius: 30px;
}

.testimony-card {
  border-bottom: 1px solid #ccc;
  font-size: 25px;
  padding: 20px 0 10px 0;
  text-align: center;
  margin: 0 15px 10px 15px;
  /* border-radius: 4px; */
}

.client-info {
  display: flex;
  align-items: center;
}

.client-info img {
  width: 50px;
  height: 50px;
  /* border-radius: 50%; */
  margin: 0px 10px;
}

.footer-content {
  display: flex;
  margin: 70px auto;
  margin-bottom: 30px;
  width: 85%;
  border-radius: 30px;
  background-color: white;
}

.contact {
  flex: 1;
  text-align: center;
  background-color: #f5f5f7;
  margin: 20px;
  border-radius: 30px;
}

.footer-policy {
  text-align: center;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  line-height: 2;
}

.report-category-box {
  border-radius: 15px;
  background-color: #f5f5f7;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  margin: 15px 0 0 15px;
}

/* AnimatedIcon.css */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-once {
  animation: bounce 1s; /* Adjust the duration to match your needs */
}

.banner {
  padding: 15px;
  display: flex;
  margin: 15px;
  border-radius: 15px;
  background-color: #64e986;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  color: #2C272B;
}

